<template>
  <div class="sidebar-extend">
    <div class="my-3 text-center">
      <div class="my-3 text-center fw-bold text-white">history</div>
      <a
        @click="eraseHistoric()"
        class="btn btn-sm btnreset float start mb-3 mx-2"
      >
        <i class="bi bi-eraser me-1"></i>Erase historic
      </a>
      <ul class="list-group list-group-flush">
        <li
          v-for="history in history.slice().reverse()"
          v-bind:key="history.date"
          @click="loadHistory(history)"
          class="list-group-item"
        >
          {{ history["url"]["q"] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.sidebar-extend {
  overflow: auto;
  height: 100vh;
}
ul {
  width: 100%;
}
.btn {
  color: white;
}
.btn:hover {
  background-color: white;
  color: black;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "sidebar_extend_history",
  computed: {
    ...mapState(["history"]),
  },
  methods: {
    loadHistory: function (historys) {
      if (this.$route.query["q"] != historys["url"]["q"]) {
        this.$store.commit("set_query", historys["url"].q);
      }
    },
    eraseHistoric: function () {
      this.$store.commit("clear_Historic");
    },
  },
  mounted: function () {
    this.$store.commit("get_History");
  },
};
</script>
