<template>
  <div>
    <div
      v-bind:class="queryHint ? 'mt-2' : ''"
      class="w-50 mx-auto input-group"
    >
      <input
        v-on:keyup.enter="search"
        v-model="recherche1"
        type="text"
        class="form-control"
        placeholder="Recherche"
        aria-label="Recherche"
        aria-describedby="basic-addon1"
      />
      <button
        v-on:click="search"
        class="btn btn-outline-secondary"
        type="button"
        id="button-addon1"
      >
        <span
          v-if="statue_search"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <i v-else class="bi bi-search"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

function etools(query, language) {
  let r =
    "https://www.etools.ch/partnerSearch.do?" +
    new URLSearchParams(
      Object.assign(
        {
          partner: "Carrot2Json",
          query: query,
          dataSourceResults: 40,
          maxRecords: 200,
        },
        {
          country: "web",
          dataSources: "all",
          language: language,
          safeSearch: true,
        }
      )
    );
  return window
    .fetch(r)
    .then(function (response) {
      return response.json();
    })
    .catch(function (e) {
      console.error(e);
    });
}

function api_mydataball(query, moteur, keyapi) {
  let r =
    process.env.VUE_APP_AURL_API_MYDATABALL +
    moteur +
    "?" +
    new URLSearchParams(
      Object.assign(
        {
          q: query,
        },
        {
          keyapi: keyapi,
        }
      )
    );
  return window
    .fetch(r)
    .then(function (response) {
      return response.json();
    })
    .catch(function (e) {
      console.error(e);
    });
}

function parser_etools(e) {
  let documents = [];
  e["response"]["mergedRecords"].forEach((element) => {
    let el = {};
    el["title"] = element["title"];
    el["snippet"] = element["text"];
    documents.push(el);
  });
  return documents;
}

function parameters_Lingo(queryHint) {
  return {
    desiredClusterCount: 30,
    preprocessing: {
      documentAssigner: {
        minClusterSize: 2,
        exactPhraseAssignment: false,
      },
      labelFilters: {
        completeLabelFilter: {
          enabled: true,
          labelOverrideThreshold: 0.65,
        },
        genitiveLabelFilter: {
          enabled: true,
        },
        minLengthLabelFilter: {
          enabled: true,
          minLength: 3,
        },
        numericLabelFilter: {
          enabled: true,
        },
        queryLabelFilter: {
          enabled: true,
        },
        stopLabelFilter: {
          enabled: true,
        },
        stopWordLabelFilter: {
          enabled: true,
        },
      },
      phraseDfThreshold: 1,
      wordDfThreshold: 1,
    },
    clusterBuilder: {
      labelAssigner: {
        "@type": "UniqueLabelAssigner",
      },
      clusterMergingThreshold: 0.7,
      phraseLabelBoost: 1.5,
      phraseLengthPenaltyStart: 8,
      phraseLengthPenaltyStop: 8,
    },
    scoreWeight: 0,
    dictionaries: {
      labelFilters: [],
      wordFilters: [],
    },
    matrixBuilder: {
      termWeighting: {
        "@type": "LogTfIdfTermWeighting",
      },
      boostFields: [],
      boostedFieldWeight: 2,
      maxWordDf: 0.9,
      maximumMatrixSize: 37500,
    },
    matrixReducer: {
      factorizationFactory: {
        "@type": "NonnegativeMatrixFactorizationEDFactory",
        factorizationQuality: "HIGH",
      },
    },
    queryHint: queryHint,
  };
}

function parameters_STC(queryHint) {
  return {
    maxClusters: 15,
    maxBaseClusters: 300,
    minBaseClusterScore: 2,
    minBaseClusterSize: 2,
    documentCountBoost: 1,
    mergeStemEquivalentBaseClusters: true,
    mergeThreshold: 0.6,
    scoreWeight: 0,
    singleTermBoost: 0.5,
    optimalPhraseLength: 3,
    optimalPhraseLengthDev: 2,
    maxWordsPerLabel: 4,
    maxPhrasesPerLabel: 3,
    maxPhraseOverlap: 0.6,
    mostGeneralPhraseCoverage: 0.5,
    ignoreWordIfInHigherDocsPercent: 0.9,
    preprocessing: {
      wordDfThreshold: 1,
    },
    queryHint: queryHint,
  };
}

function parameters_kmeans(queryHint) {
  return {
    clusterCount: 25,
    maxIterations: 15,
    partitionCount: 2,
    labelCount: 3,
    matrixBuilder: {
      termWeighting: {
        "@type": "LogTfIdfTermWeighting",
      },
      boostFields: [],
      boostedFieldWeight: 2,
      maxWordDf: 0.9,
      maximumMatrixSize: 37500,
    },
    preprocessing: {
      wordDfThreshold: 1,
    },
    useDimensionalityReduction: true,
    matrixReducer: {
      factorizationFactory: {
        "@type": "NonnegativeMatrixFactorizationEDFactory",
        factorizationQuality: "HIGH",
      },
    },
    queryHint: queryHint,
  };
}

function parameter(
  documents = {},
  algorithm = "Lingo",
  queryHint = "",
  language = "French"
) {
  let parameters;

  if (algorithm == "Lingo") {
    parameters = parameters_Lingo(queryHint);
  } else if (algorithm == "STC") {
    parameters = parameters_STC(queryHint);
  } else if (algorithm == "Bisecting K-Means") {
    parameters = parameters_kmeans(queryHint);
  }
  return {
    algorithm: algorithm,
    language: language,
    parameters: parameters,
    documents: documents,
  };
}

function carrot(parameter) {
  return window
    .fetch(
      process.env.VUE_APP_AURL_CLUSTER +
        new URLSearchParams(
          Object.assign({
            template: "frontend-default",
            serviceInfo: "",
          })
        ),
      {
        method: "POST",
        body: JSON.stringify(parameter),
        headers: {
          "content-type": "application/json",
        },
      }
    )
    .then(function (response) {
      return response.json();
    })
    .catch(function (e) {
      console.error(e);
    });
}

export default {
  name: "Recherche",
  computed: {
    ...mapState(["rParserEtools", "query", "queryHint", "keyapi"]),
  },
  data: function () {
    return {
      recherche1: "",
      code_cashe: "",
      statue_search: false,
    };
  },
  watch: {
    query: function () {
      this.recherche1 = this.query;
      this.search();
    },
  },
  methods: {
    search: async function () {
      if (!this.recherche1.length) {
        return;
      }

      this.statue_search = true;
      const engine = this.$route.query["engine"] ?? "etools";
      const langue = this.$route.query["langue"] ?? "en";
      const algorithm = this.$route.query["algorithm"] ?? "Lingo";

      if (this.code_cashe != this.recherche1 + langue + engine) {
        // Sauvegarder les résultats de la recherche
        if (!process.env.VUE_APP_AURL_CLUSTER) {
          this.$store.commit(
            "set_Rparser_mydataball",
            await api_mydataball(this.recherche1, engine, this.keyapi)
          );
        } else {
          this.$store.commit(
            "set_Rparser_etools",
            await etools(this.recherche1, langue)
          );
        }
        // Reset les éléments selectionnés
        this.$store.commit("set_sort", {
          list: [],
          labels: [],
          id: -1,
        });
        // redirection après recherche sur la page home
        if (this.$route.name == "recherche-home") {
          this.$router.push({
            name: "recherche-results",
            query: Object.assign({}, this.$route.query, {
              q: this.recherche1,
            }),
          });
        } else {
          if (this.$route.query["q"] != this.recherche1) {
            this.$router.replace({
              query: Object.assign({}, this.$route.query, {
                q: this.recherche1,
              }),
            });
          }
        }
        // TODO this.recherche1 + langue + engine / this.$route.query
        this.code_cashe = this.recherche1 + langue + engine;
        this.$store.commit("set_queryHint", this.$route.query);
      }
      // Sauvegarder les résultats de carrot
      if (engine == "etools" && process.env.VUE_APP_AURL_CLUSTER) {
        this.$store.commit(
          "set_reponse_carrot",
          await carrot(
            parameter(
              parser_etools(this.rParserEtools),
              algorithm,
              this.recherche1
            )
          )
        );
      }

      this.statue_search = false;
    },
  },
  mounted: function () {
    if (this.$route.query["q"]) {
      this.recherche1 = this.$route.query["q"];
      this.search();
    }
  },
};
</script>
