<template>
  <div class="sidebar-extend overflow-auto parameterHeight">
    <div v-if="VerifAPI" class="text-center">
      <div class="my-3 fw-bold text-white">Key</div>
      <input
        type="text"
        class="mx-auto form-control w-75 mx-auto"
        id=""
        placeholder="key"
        v-model="keyapi"
      />
      <hr />
    </div>
    <div class="my-3 text-center fw-bold text-white">Global parameter</div>
    <div class="text-center">
      <label class="text-white m-auto">Change search engine</label>
      <select
        class="form-select w-75 my-3 mx-auto"
        @change="changeEngine($event)"
        v-model="defaultEngine"
      >
        <option
          v-for="engine in listeEngines"
          v-bind:value="engine.value"
          v-bind:key="engine.text"
          v-bind:disabled="engine.disabled"
        >
          {{ engine.text }}
        </option>
      </select>
    </div>
    <div class="text-center">
      <label class="text-white m-auto">Change algorythm</label>
      <select
        class="form-select w-75 my-3 mx-auto"
        @change="changeAlgorithm($event)"
        v-model="defaultAlgorithme"
      >
        <option
          v-for="listeAlgorithme in listeAlgorithmes"
          v-bind:value="listeAlgorithme.value"
          v-bind:key="listeAlgorithme.text"
        >
          {{ listeAlgorithme.text }}
        </option>
      </select>
    </div>
    <div class="text-center">
      <hr />
      <div class="my-3 fw-bold text-white">Web parameter</div>
      <label class="text-white m-auto">Select language</label>
      <select
        class="form-select w-75 my-3 mx-auto"
        @change="changeLangue($event)"
        v-model="defaultLangue"
      >
        <option
          v-for="langue in listeLangues"
          v-bind:value="langue.value"
          v-bind:key="langue.text"
        >
          {{ langue.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<style scoped>
.parameterHeight {
  height: 100vh;
}
</style>

<script>
export default {
  name: "sidebar_extend",
  computed: {
    keyapi: {
      get() {
        return this.$store.state.keyapi;
      },
      set(keyapi) {
        this.$store.commit("set_keyapi", keyapi);
      },
    },
  },
  methods: {
    changeEngine: function (engine) {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, {
          engine: engine.target.value,
        }),
      });
    },
    changeAlgorithm: function (algorithm) {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, {
          algorithm: algorithm.target.value,
        }),
      });
    },
    changeLangue: function (langue) {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, {
          langue: langue.target.value,
        }),
      });
    },
  },
  data: function () {
    return {
      VerifAPI: process.env.VUE_APP_AURL_API_MYDATABALL ? true : false,
      defaultEngine: this.$route.query["engine"] ?? "etools",
      defaultAlgorithme: this.$route.query["algorithm"] ?? "Lingo",
      defaultLangue: this.$route.query["langue"] ?? "all",
      listeEngines: [
        { text: "eTools", value: "etools", disabled: false },
        {
          text: "Searx",
          value: "searx",
          disabled: process.env.VUE_APP_AURL_API_MYDATABALL ? false : true,
        },
        {
          text: "Solr",
          value: "solr",
          disabled: process.env.VUE_APP_AURL_API_MYDATABALL ? false : true,
        },
      ],
      listeAlgorithmes: [
        { text: "Lingo", value: "Lingo" },
        { text: "STC", value: "STC" },
        { text: "k-means", value: "Bisecting K-Means" },
      ],
      listeLangues: [
        { text: "All", value: "all" },
        { text: "English", value: "en" },
        { text: "Français", value: "fr" },
      ],
    };
  },
};
</script>
