<template>
  <div class="couleurSidebar d-flex flex-column flex-shrink-0">
    <router-link class="m-3" to="/">
      <img class="logo" src="../assets/logo.png" />
    </router-link>

    <div v-if="getroute()">
      <router-link to="/">
        <i class="bi bi-search"></i>
      </router-link>
    </div>
    <div class="divlogo my-1" v-else>
      <div
        class="action-item my-2"
        v-bind:class="position == 'gear' ? 'active' : ''"
      >
        <a
          v-on:click="gestionSidebar('gear')"
          title="Paramètres de la recherche"
        >
          <i class="logoSide bi bi-gear"></i>
        </a>
        <div class="active-item-indicator"></div>
      </div>
      <div
        class="action-item"
        v-bind:class="position == 'history' ? 'active' : ''"
      >
        <a
          v-on:click="gestionSidebar('history')"
          title="Historique des recherches"
        >
          <i class="bi bi-clock-history"></i>
        </a>
        <div class="active-item-indicator"></div>
      </div>
    </div>

    <div class="mt-auto pb-2">
      <router-link to="/contact" title="Page de contact">
        <i class="bi bi-envelope-fill my-2"></i>
      </router-link>
      <router-link to="/about" title="Page à propos">
        <i class="bi bi-question-circle-fill"></i>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
i {
  width: 100%;
  display: inline-block;
}
i::before {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.divlogo {
  width: 100%;
}
.couleurSidebar {
  background-color: #22609e;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 26px;
  color: aliceblue;
}
.logo {
  width: 35px;
  height: 35px;
  object-position: 0 0;
  object-fit: cover;
}
.bi {
  text-decoration: none;
  color: white;
}
.action-item {
  position: relative;
}

div.active > div.active-item-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 2px solid;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "Sidebar",
  computed: {
    ...mapState(["position"]),
  },
  methods: {
    gestionSidebar: function (response) {
      if (this.position == response) {
        this.$store.commit("set_position", "search");
      } else {
        this.$store.commit("set_position", response);
      }
    },
    getroute: function () {
      return this.$route["name"].match(/^recherche-/) == null;
    },
  },
};
</script>
