<template>
  <div id="home">
    <div class="conteneur">
      <div class="sidebar">
        <sidebar />
      </div>
      <div v-if="$store.state.position == 'gear'" class="sidebar-extend">
        <sidebarextend />
      </div>
      <div
        v-else-if="$store.state.position == 'history'"
        class="sidebar-extend"
      >
        <sidebarextendhistory />
      </div>
      <div v-bind:class="queryHint ? 'my-auto' : ''" class="recherche">
        <img
          v-if="queryHint"
          class="logo mx-auto d-block"
          src="../assets/logo.png"
          alt="Logo MyDataBall"
          height="92"
        />
        <barreRecherche />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import barreRecherche from "@/components/barreRecherche.vue";
import sidebar from "@/components/sidebar.vue";
import sidebarextend from "@/components/sidebar_extend.vue";
import sidebarextendhistory from "@/components/sidebar_extend_history.vue";

export default {
  name: "Home",
  components: {
    sidebar,
    sidebarextend,
    sidebarextendhistory,
    barreRecherche,
  },
  computed: {
    ...mapState(["queryHint"]),
  },
  mounted: function () {
    this.$store.commit("set_queryHint", { url: { q: "" } });
  },
};
</script>

<style scoped>
#home {
  height: 100vh;
}
.conteneur {
  display: flex;
  height: 100%;
}
.recherche {
  flex: 98%;
}
.sidebar-extend {
  background-color: #7a9dce;
  width: 250px;
  margin: 0 10px 0 0;
}
.logo {
  mix-blend-mode: exclusion;
  margin-bottom: 20px;
  margin-top: -92px;
}
</style>
