import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    position: "search",
    reponse_carrot: {
      clusters: [],
      serviceInfo: { clusteringTimeMillis: "" },
    },
    keyapi: "",
    query: "",
    history: [],
    queryHint: true,
    rParserEtools: {
      document: {},
      request: {},
      response: { mergedRecords: [] },
    },
    sort: { list: [], id: -1 },
  },
  mutations: {
    set_position(state, position) {
      state.position = position;
    },
    set_reponse_carrot(state, reponse) {
      state.reponse_carrot = reponse;
    },
    set_queryHint(state, query) {
      if (query == undefined || query["q"] == undefined) {
        return;
      }
      if (localStorage.getItem("history")) {
        state.history = JSON.parse(localStorage.getItem("history"));
      }
      if (query["q"].length != 0) {
        if (state.history.length != 0) {
          if (
            state.history[state.history.length - 1]["url"]["q"] == query["q"]
          ) {
            return;
          }
        }
        state.query = query["q"];
        state.history.push({
          date: new Date().getTime(),
          url: query,
        });
        localStorage.setItem("history", JSON.stringify(state.history));
      }
    },
    set_Rparser_etools(state, rParserEtools) {
      state.rParserEtools = rParserEtools;
    },
    set_Rparser_mydataball(state, rParsermydataball) {
      state.rParserEtools = {
        document: [],
        request: {},
        response: {
          mergedRecords: rParsermydataball["resultat"] ?? [],
          durationMs: rParsermydataball["durationMs"],
        },
      };
      state.reponse_carrot = {
        clusters: rParsermydataball["resultatCarrot"],
        serviceInfo: {
          clusteringTimeMillis: rParsermydataball["clusteringTimeMillis"],
        },
      };
    },
    set_sort(state, sort) {
      state.sort = sort;
    },
    set_query(state, query) {
      state.query = query;
    },
    clear_Historic(state) {
      state.history = [];
      localStorage.clear("history");
    },
    get_History(state) {
      if (localStorage.getItem("history")) {
        state.history = JSON.parse(localStorage.getItem("history"));
      }
    },
    set_keyapi(state, keyapi) {
      state.keyapi = keyapi;
    },
  },
  actions: {},
  modules: {},
});
